// aqui declaro predeterninadas

$naranja: #E35C16;
$negro: #001e24;
$gris: #eeeeee;
$gris_oscuro: #686868;
$blanco:#f5f5f5;



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body{
   background: $gris;
}
a{
    color: $blanco;
}

.active{
    color: $negro;
}


.buttomPrimario{

    background: $naranja;
    width: 100%,;
    padding: 7px 30px;
    border-radius: 20px;
    margin-bottom: 30px;
    a{
        text-align: center;
        padding: 0;
        margin: 0;
    }

}

.buttomPrimario:hover{

    background: $negro;
    width: 100%,;
    padding: 7px 30px;
    border-radius: 20px;
    margin-bottom: 30px;
    a{
        color:$blanco;
      
    }

}

.buttom2{
    margin: auto;
    background: $blanco;
    width: 60%,;
    padding: 7px 30px;
    border-radius: 20px;
    margin-bottom: 30px;
    a{
        color:$blanco;
      
    }
}

.buttom2:hover{

    background: $negro;
    padding: 7px 30px;
    border-radius: 20px;
    margin-bottom: 30px;
    a{
        color:$blanco;
      
    }

}

.flotante{
    
    z-index: 999999;
    position: absolute;
    position: fixed;
    right: 30px;
    bottom: 30px;
    &:hover{
        cursor: pointer;
        transform: scale(1.1);
        transition: all .3s linear;
    }
    img{
        width: 110px;
    }
}





