.BannerDedicado_container{
    background:$naranja;
    height: auto;
    
   
 
    .container{
        display: flex;
        justify-content: space-evenly;
        align-self: center;
        flex-wrap: wrap-reverse;
        align-content: center;
        align-items: center;
        height: auto;
    }
    section{
        justify-content: center;
        padding: 50px;
        text-align: center;
        align-items: center;
        margin: auto;
        .banner_text{
            margin: auto;
        }

    
    }
    h2{
        font-weight: 800;
        color: $blanco;
    }

    a{
        text-decoration: none;
        color: $negro;
    }


}

