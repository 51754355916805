.container_pagar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        text-align: center;
    }

    .botones_link{
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        padding: 30px;
        text-decoration: none;
        a{
            text-decoration: none;
        }
    button{
        width: 120px;
        height: 40px;
        background-color: $naranja;
        border: none;
        border-radius: 7px;

    }

   
}

    .puntos_autorizados{
       
        background: $blanco;
        height: auto;
        padding: 30px;
        p{
            text-align: center;
        }
        .marcas{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            padding: 30px;
            .logo_marcas{
              
                img{
                    width: 150px;
                }
            }
        }

        h5{
            text-align: center;
            margin: 30px ;
        }

    
    }

.oficinas{
    display: flex;
    flex-wrap: wrap;
    
    p{
        text-align: start;
        border-left: 2px solid $negro;
        padding-left: 20px;
        font-weight: 600;
    }

    .puntos{
        padding: 20px 30px;
       
    }


}
   
}

.logo_pagar{
    align-items: center;
    display: flex;
img{
    width: 200px;
    margin-right: 30px;
}

}