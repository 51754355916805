.contacto{
    display: flex;
    justify-content: center;
    height: auto;
    margin: 50px auto;

    form{
        max-width: 600px;
        min-width: 320px;
        padding: 50px 30px ;
       
    }
    h2{
        text-align: center;
       margin-bottom: 50px ;
    }
    button{
        text-align: center;
        width: 250px;
        height: 35px;
        background: $naranja;
        color: $blanco;
        margin: 35px 0px;
        border: none;
    border-radius: 20px ;    }
    
}

