
.hogar{
    margin-top: -16px;

    .banners{
        width: 100%;
        height: 575px; 
       
    }
    .uno{
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-evenly;
        align-items: center;
        background-image: url("../img/banner1.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        align-content: center;
       
        h1{
        font-size: 80px;
        font-weight: 800;

        //<-- atento a esta linea truco degradado texto//
        display: inline-block;
        color: #000;
        background: #fff;
        mix-blend-mode: multiply;
        position: relative;
       }
       h1:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, red, rgb(255, 166, 0));
        mix-blend-mode: screen; //<-- atento a esta linea truco degradado texto//
        position: absolute;
        top: 0;
        left: 0;
    }
       h2{
        font-weight: 600;
        line-height: .3;

       }

        }
        .dos{
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("../img/banner2.png");
            background-position: center;
            background-size: cover;
            img{
              height: 100%;
            }
           
        }
        .tres{
            
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            background-image: url("../img/banner3.png");
            background-position: center;
             background-size: cover;
            
        h1{
            font-size: 80px;
            font-weight: 800;
            color: $naranja;
            line-height: .9;


            //<-- atento a esta linea truco degradado texto//
            display: inline-block;
            color: #000;
            background: #fff;
            mix-blend-mode: multiply;
            position: relative;
           }
//<-- atento a esta linea truco degradado texto//
           h1:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, red, rgb(255, 166, 0));
            mix-blend-mode: screen; //<-- atento a esta linea truco degradado texto//
            position: absolute;
            top: 0;
            left: 0;
        }
           h2{
           
            font-weight: 800;
            line-height: .9;

           }
          .img_tres{
            margin-left: -200px;
            max-width: 600px;
           img{
            max-width: 100%;
           }
           
        }

    }
  
}

.btn_pagar{
    margin: auto;
    position: relative;
    margin-top: -53px;
    z-index: 999999;
    border-radius: 7px;
    padding: 10px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    

}

  
.botones_home{
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    flex-wrap: wrap;
    background: $negro;
    border-radius: 15px 0px 15px 0;
    
    a{
        text-decoration: none;
        color: $negro;
        padding-top: 10px;
        font-weight: 600;
       
        
    }
    .btn_home{
        text-align: center;
        width: 250px;
        height: 70px;
      
        display: flex;
        align-items: center;
        justify-content: center;
        
        flex-direction: column;
        color: $blanco;
        
        border-left: 1px solid $blanco;
        
        &:hover{
           background: $naranja;
            color: $blanco;
            cursor: pointer;
            transform: scale(1.1);
        }
        
    }
}


@media screen and (max-width: 468px) {
    
    .hogar{
        margin-top: 0;
     
        .banners{
            height: 80vh;

        }
        
        .uno{
            text-align: center;
            background-image: url("../img/banner1.png");
            background-position: center;
            background-size: cover;
            align-content: center;
            h1{
                font-size: 50px;
            }
            h2{
                line-height: 1;
            }
            img{
                width: 400px;
            }
            }
            .dos{
                background-image: url("../img/banner2.png");
                background-position: right;
                background-size: cover;
                img{
                    margin-top: -150px;
                    width: 600px;
                    height: auto;
                  }
                 
        
            }
            .tres{
                display: flex;
                flex-direction: column-reverse;
               
                background-image: url("../img/banner3.png");
                background-position: center;
                background-size: cover;
                align-content: center;
                text-align: center;

            h1{
                font-size: 55px;
               padding: 30px;
            }
            h2{

                padding: 0px 30px;
            }

           .text_tres{
            margin-top: -150px;


            }
            .img_tres{
            margin: 0;    
            width: 350px;
            }
    
    
        }

    }

}


.hogar_text{
    padding: 50px ;
    margin: auto;
    text-align: center;
    max-width: 850px;
    text-align: center;
    h1{
        font-weight: 800;
    }
    }

.container_hogar{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-content: center;
    height: auto;
    gap: 30px;
    margin-bottom: 80px;

}




.btn_pagar{
    margin: auto;
    position: relative;
    margin-top: -53px;
    z-index: 999999;
    border-radius: 7px;
    padding: 10px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    

}

  
.botones_home{
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    flex-wrap: wrap;
    background: $negro;
    border-radius: 15px 0px 15px 0;
    
    a{
        text-decoration: none;
        color: $negro;
        padding-top: 10px;
        font-weight: 600;
        
    }
    .btn_home{
        text-align: center;
        width: 250px;
        height: 70px;
        border-right:none;
        border-bottom: 1px solid $blanco;
        border-left:none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $blanco;
        
        &:hover{
           background: $naranja;
            color: $blanco;
            cursor: pointer;
            transform: scale(1.1);
        }
        
    }
}