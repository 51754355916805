.nuestrosClientes{
    width: 100%;
    height: auto;
    background: $blanco;
    text-align: center;
h2{
    padding: 80px 0;} 
    
    .logoclientes{
        
        padding-bottom: 80px;
        img{
            width: 350px;
            padding: 20px 50px;
        }
    }
   

}