

.registrer{
    .entrar {

        &:hover{
            background: $negro;
        }
        a{
            text-decoration: none;
            color: $negro;
            &:hover{
                background: $negro;
                color: $blanco;
            }
        }
    }
}

nav{
    
    .nav_item{
        display: flex;
        justify-content: center;
        padding: 10px 20px;
        color: aliceblue;
        list-style: none;
        background: $naranja;
    }
   
    .nav_item a:hover{
        color: $negro;
        border-bottom: 2px solid $negro;
        border-top: 2px solid $negro;
        cursor: pointer;
        text-decoration: none;
    }
    a{
        padding: 15px 30px;
        text-decoration: none;
        font-style: none;
    }

  }

.fondo_header{
    width: 100%;
    height:auto;
    
    background: $blanco;
   
}
.container {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-self: center; 
    
    a{
        width: 120px;
        height: auto;
    }
}


    .hamburguesa {

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 30px;
        height: 30px;
        align-content: center;
        cursor: pointer;     
       span {
        width: 30px;
        height: 2px;
        background-color: $negro;
        border-radius: 2px;
        transform-origin: 7px 0px;
        transition: all 0.2s linear; 
        
        }
      }
 
.menu{
    display: none;
  }

@media screen and (max-width: 468px) {

nav{
   width: 100%;
    position: absolute;
}
    .menu{
      display: block;
      margin-top: 10px;
    }
    .nav_item {
        width: 100%;
        position: absolute;
        z-index: 999;
        display: flex;
        flex-direction: column;
        height: 100vh;
        left: 0;
        transform: translateX(-100%);
        transition: all 1s ease; 
        opacity: .98;
      
       
      li{
        text-align: end;
        padding: 20px;
      }
      a {
        padding: 5px 30px;
        color: #e9e9e9;
        text-decoration: none; 
        
      }
     }

   
    .tel{
        display: none;
    }
} 




 .close span{
            transform: rotate(45deg) translate(0px, 0px);
        }

        .close span:nth-child(2){
            display: none;
        } 

        .close span:nth-child(3){
            transform: rotate(-45deg) translate(-3px, 1px);
        }

    .open{
         transform: translateX(-50%) !important;
        }

    .close{
            transform: translateX(0) ;
           }




